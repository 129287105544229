<mat-toolbar color="primary" class="text-center">
  Ritinha Memory Game
</mat-toolbar>
<div class="p-16">
  <p class="text-center">Jogo feito com muito amor para a Ritinha! <br>
    Happy Birthday ❤</p>
  <div class="grid p-16">
    <app-game-card *ngFor="let c of cards; let idx=index" [data]="c" (cardClicked)="cardClicked(idx)">
    </app-game-card>
  </div>
</div>
<div class="count">
  <h1>Jogadas efetuadas: {{playCount}}</h1>
</div>